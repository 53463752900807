<template>
  <div>
    <div  class="flex flex-wrap-reverse items-center">
      <div 
        v-permission="'classes.create'"
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>

    <Grid :service="classService" route_name="classes" ref="grid">
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div v-html-safe="tr.id"></div>
          </vs-td>
          <vs-td width="25%" key="title">
            <div v-html-safe="tr.title"></div>
          </vs-td>
          <vs-td width="30%" key="description">{{
            tr.description ? tr.description : ''
          }}</vs-td>
          <vs-td width="5%" key="actions">
              <feather-icon
                icon="EditIcon"
                v-permission="'classes.edit'"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="edit(props.data[indextr].id)"
              />
              <feather-icon
                icon="Trash2Icon"
                v-permission="'classes.delete'"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="destroy(props.data[indextr])"
              />
          </vs-td>
        </vs-tr>
      </template>

    </Grid>
  </div>
</template>

<script>
import ClassService from '@/services/api/ClassService'

export default {
  data: () => ({
    selected: [],
    classService: null
  }),
  methods: {
    addNew() {
      this.$router.push('/classes/create')
    },
    edit(classId) {
      this.$router.push(`/classes/${classId}/edit`)
    },
    destroy(classes) {
      this.$refs['grid'].destroy(classes)
    }
  },
  beforeMount() {
    this.classService = ClassService.build(this.$vs)
  }
}
</script>
